require("./../bower_components/sass-bootstrap/dist/js/bootstrap.js");


//include angular
require("./../bower_components/angular/angular.js");
window._ = require("./../bower_components/lodash/dist/lodash.compat.js");
require("./../bower_components/angular-ui-router/release/angular-ui-router.js");
require("./../bower_components/angular-animate/angular-animate.js");
require("./../bower_components/angular-inview/angular-inview.js");
require("./../bower_components/angular-timer/dist/angular-timer.js");
require("./../bower_components/angular-google-maps/dist/angular-google-maps.js");
require("./../bower_components/classie/classie.js");
window.Snap = require('snapsvg');

require("./../bower_components/video.js/dist/video-js/video.js");
require("./../bower_components/jquery-smartresize/jquery.debouncedresize");